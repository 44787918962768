import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const Logout: React.FC = () => (
  <div data-testid="logout-key">
    <FormattedMessage {...messages.logout} />
  </div>
);

Logout.displayName = 'Logout';
