import { gql } from '@apollo/client';

export const GET_GUEST_OCCURRENCE_ID = gql`
  query getDriveOccurrenceGuest($id: Int!) {
    sch_drive_occurrence_guest(where: { id: { _eq: $id } }) {
      from
      to
      status
      id
      drive_occurrence_id
      drive_occurrence {
        drive_schedule_id
        drive_schedule {
          end_date
          start_date
          status
          timezone
          booking_end_date
          booking_start_date
        }
        end_date
        start_date
        status
      }
    }
    canx_get_drive_occurrence_guest(drive_occurrence_guest_id: $id) {
      pay_item {
        order_items {
          order_id
        }
      }
    }
  }
`;

export const GET_DRIVE_OCCURRENCE_GUEST = gql`
  query getDriveOccurrenceGuestById($driveOccurrenceGuestId: Int!) {
    canx_get_drive_occurrence_guest(drive_occurrence_guest_id: $driveOccurrenceGuestId) {
      id
      from
      to
      status
      meeting {
        id
        name
        from
        to
        meeting_type
        meeting_status
      }
      pay_item {
        id
        status
        order_items {
          item_id
          order_id
          unit_price
          total_price
          quantity
          order {
            id
            status
            transactions {
              payment_id
              external_id
              type
              status
              total_amount
            }
          }
        }
        sku {
          currency_code
          plan_type
          name
          refund_rules {
            refund_percent
            timeframe
          }
        }
        eligible_refund_rule {
          timeframe
          refund_percent
        }
        drive_occurrence_guest {
          from
          id
          status
          to
        }
      }
      drive_occurrence {
        start_date
        end_date
        status
        workflow_step_id
        drive_schedule_id
      }
      drive_schedule {
        id
        booking_start_date
        booking_end_date
        drive_type
        name
        start_date
        end_date
        status
        timezone
        location
        slot_capacity
        slot_duration
      }
    }
  }
`;

export const GET_DRIVE_OCCURRENCE = gql`
  query getScheduleOccurrenceDrive($drive_schedule_id: Int!) {
    sch_drive_occurrence(
      where: { drive_schedule_id: { _eq: $drive_schedule_id }, status: { _eq: SCHEDULED } }
      order_by: { start_date: asc }
    ) {
      drive_schedule_id
      status
      end_date
      start_date
      id
    }
  }
`;

export const CONFIRM_DRIVE_MEETING_SLOT = gql`
  mutation confirmDriveSlot($drive_id: Int!, $from: timestamptz!, $to: timestamptz!) {
    sch_confirm_drive_slot(data: { drive_occurrence_id: $drive_id, from: $from, to: $to }) {
      data
      error_message
      success
    }
  }
`;

export const CANCEL_DRIVE_OCCURRENCE_GUEST = gql`
  mutation CancelDriveOccurrenceGuest($driveOccurrenceGuestId: Int!) {
    canx_cancel_drive_occurrence_guest(drive_occurrence_guest_id: $driveOccurrenceGuestId) {
      reason
      message
      drive_occurrence_guest {
        id
        to
        from
      }
      drive_schedule_id
      drive_occurrence_id
    }
  }
`;

export const SUBSCRIBE_DRIVE_LOBBY_MEETING = gql`
  subscription getDriveLobbyMeeting($drive_schedule_id: Int!) {
    sch_drive_lobby(where: { drive_schedule_id: { _eq: $drive_schedule_id } }) {
      drive_schedule {
        slot_capacity
        slot_duration
      }
      candidate_user_id
      drive_guest_sequence
      drive_occurrence_guest_id
      drive_occurrence_id
      drive_schedule_id
      meeting_id
      meeting {
        duration
        from
        meeting_status
        meeting_type
        meeting_participants {
          online_meeting_link
        }
      }
    }
  }
`;

export const GET_HEART_BEAT = gql`
  query getLobbyHeartBeat($drive_occurrence_id: Int!) {
    sch_heart_beat(data: { drive_occurrence_id: $drive_occurrence_id }) {
      data {
        drive_occurrence_id
        last_active_time
      }
      error_message
      success
    }
  }
`;

export const UPDATE_LOBBY_DRIVE_MEMBER_STATUS = gql`
  mutation updateDriveMemberStatus($drive_schedule_id: Int!, $drive_occurrence_id: Int!, $status: String!) {
    sch_update_drive_member_status(
      data: { drive_schedule_id: $drive_schedule_id, drive_occurrence_id: $drive_occurrence_id, status: $status }
    ) {
      data
      error_message
      success
    }
  }
`;
