import { call, debounce, fork, put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ConfirmationResult, UserCredential } from 'firebase/auth';
import { first, get } from 'lodash';

import {
  authenticate,
  authenticateFailed,
  fetchCandidateUser,
  getEmailOtp,
  getEmailOtpSuccess,
  getPhoneFirebaseOtp,
  getPhoneFirebaseOtpSuccess,
  initialize,
  initializeComplete,
  initializeSocialLogin,
  storeCandidateUser,
  storeIdToken,
  storeQueryParams,
  storeStoredSession,
  unAuthenticate,
  verifyEmailOtp,
  verifyFirebaseOtp,
} from '@containers/Auth/slice';

import { catchError } from '@utils/sentry';
import initializeFirebase, { firebaseLogout, getFirebaseIdToken } from '@utils/firebase';
import { QUERY_PARAMS_STORAGE_KEY, clearLocalStorage, localStorageSetItem } from '@utils/localStorageHelpers';
import { fetchCountryCodeApi } from '@utils/fetchCountryCode';
import postData from '@utils/postData';
import fetchData from '@utils/fetchData';

import { SagaCallback } from '@store/types';
import client from '@utils/apollo';
import { selectAuth, selectIdToken, selectQueryParams } from './selectors';
import {
  GET_CURRENT_CANDIDATE_USER,
  GET_CUSTOM_TOKEN,
  GET_CUSTOM_TOKEN_BY_ID_TOKEN,
  OTP_REQUEST,
  SIGNUP,
  VERIFY_OTP,
} from './queries';
import {
  AuthState,
  AuthenticateWorker,
  Candidate,
  CurrentCandidateUser,
  EmailInput,
  FirebaseVerifyOtpPayload,
  GetCustomTokenPayload,
  InitializeSocialLoginPayload,
  ParsedToken,
  PhoneInput,
  QueryParams,
  SignUpResponsePayload,
  SocialLoginSuccessPayload,
  VerifyOtpInput,
} from './types';
import {
  authenticateFirebase,
  getAllParams,
  getStoredQueryParams,
  initSocialLogin,
  isTokenExpired,
  parseToken,
  signInCredential,
  signInPhone,
} from './helpers';

export function* initializeWorker(): Generator {
  try {
    yield call(initializeFirebase);
    let params = (yield call(getAllParams)) as QueryParams;
    const storedParams = (yield call(getStoredQueryParams)) as QueryParams;
    if (params.c || params.tid) {
      yield call(firebaseLogout);
      yield call(clearLocalStorage);
    } else {
      params = { ...storedParams, ...params };
    }
    yield put(storeQueryParams(params));
    // if token is not expired means it is authenticated
    const idToken = (yield call(getFirebaseIdToken)) as string;
    if (idToken && !isTokenExpired(idToken)) {
      // redirect to authenticate
      const parsedToken = (yield call(parseToken, idToken)) as ParsedToken;
      const session = {
        accessToken: idToken,
      };
      yield put(storeStoredSession(session));
      yield put(authenticate({ ...session, parsedToken }));
    } else if (params.c) {
      yield call(authenticateWorker, {
        hash_token: params.c as string,
      });
    }
    yield put(initializeComplete());
  } catch (error) {
    yield call(catchError, {
      title: 'Auth initializeWorker',
      error: error as Error,
    });
  }
}

export function* getEmailOtpWorker({ payload }: PayloadAction<EmailInput>): Generator {
  try {
    yield call(postData, {
      queryString: OTP_REQUEST,
      payload: {
        username: payload?.username,
      },
      spreadPayload: true,
      context: {
        skipAuthorization: true,
        skipHasuraRole: true,
      },
    });

    yield put(getEmailOtpSuccess({ username: payload?.username }));
    if (payload?.callback?.onSuccess) {
      yield call(payload.callback.onSuccess);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'Email Otp Worker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* getPhoneFireBaseOtpWorker({ payload }: PayloadAction<PhoneInput>): Generator {
  try {
    const result = yield call(signInPhone, payload?.phone as string);
    if (result) {
      const { verificationId } = result as ConfirmationResult;
      yield put(getPhoneFirebaseOtpSuccess({ verificationId, phone: payload?.phone }));
    } else {
      // TODO: handle no result case
      throw Error('Not able to generate the Id token');
    }
    if (payload.callback.onSuccess) {
      yield call(payload.callback.onSuccess);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'Firebase Otp Worker',
      error: error as Error,
    });
    if (payload.callback.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* verifyEmailOtpWorker({ payload }: PayloadAction<VerifyOtpInput>): Generator {
  try {
    const state = (yield select(selectAuth)) as AuthState;
    const response = yield call(postData, {
      queryString: VERIFY_OTP,
      payload: {
        username: payload?.username,
        tenant_id: state?.queryParams?.tid && Number(state?.queryParams?.tid),
        code: payload?.code,
      },
      context: {
        skipAuthorization: true,
        skipHasuraRole: true,
      },
    });
    const idToken = get(response, 'data.canx_verify_otp.data.id_token');
    if (idToken) {
      yield put(storeIdToken(idToken));
    } else {
      throw Error('Not able to generate the Id token');
    }
    if (payload?.callback?.onSuccess) {
      yield call(payload.callback.onSuccess);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'Verify Otp Worker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* verifyFirebaseOtpWorker({ payload }: PayloadAction<FirebaseVerifyOtpPayload>): Generator {
  try {
    const state = (yield select(selectAuth)) as AuthState;
    const result = yield call(signInCredential, state.verificationId, payload?.code);
    if (result) {
      const { user } = result as UserCredential;
      if (user) {
        // @ts-expect-error // TODO: stsTokenManager is not defined in User type custom type have to define
        const stsTokenManage = user?.stsTokenManager?.toJSON();
        yield put(storeIdToken(stsTokenManage?.accessToken as string));
      } else {
        // TODO: handle no token generation
        throw Error('Failed to generate access token from firebase');
      }
    } else {
      // TODO: no result
      throw Error('Not able to sign in with firebase');
    }
    if (payload?.callback?.onSuccess) {
      yield call(payload.callback.onSuccess);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'firebase verify Otp Worker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* signupWorker(): Generator {
  try {
    const idToken = (yield select(selectIdToken)) as string;
    const queryParams = (yield select(selectQueryParams)) as QueryParams;
    const response = (yield call(postData, {
      queryString: SIGNUP,
      payload: {
        id_token: idToken,
        tenant_id: queryParams.tid && Number(queryParams.tid),
      },
      context: {
        skipAuthorization: true,
        skipHasuraRole: true,
      },
    })) as SignUpResponsePayload;

    const result = response?.data?.canx_signup?.[0];
    if (result && result.success) {
      const accessToken = result.data.access_token;
      yield call(authenticateWorker, { id_token: accessToken as string });
    } else {
      const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : (result?.error_message as string);
      throw Error(`Failed in signupWorker: ${err}`);
    }
  } catch (error) {
    yield put(authenticateFailed());
    yield call(catchError, {
      title: 'Authenticate User Worker',
      error: error as Error,
    });
  }
}

export function* authenticateWorker({ hash_token, id_token }: AuthenticateWorker): Generator {
  try {
    let response;
    if (id_token) {
      response = (yield call(postData, {
        queryString: GET_CUSTOM_TOKEN_BY_ID_TOKEN,
        payload: {
          id_token,
        },
        spreadPayload: true,
        context: {
          skipAuthorization: true,
          skipHasuraRole: true,
        },
      })) as GetCustomTokenPayload;
    } else {
      response = (yield call(postData, {
        queryString: GET_CUSTOM_TOKEN,
        payload: {
          hash_token,
        },
        spreadPayload: true,
        context: {
          skipAuthorization: true,
          skipHasuraRole: true,
        },
      })) as GetCustomTokenPayload;
    }
    const customToken = response?.data?.auth_get_custom_token?.custom_token;
    if (customToken) {
      const idToken = (yield call(authenticateFirebase, {
        customToken,
      })) as string;
      /*
        The idToken which is getting generate in the initial state from firebase
        doesn't contains the claims. So we have to collect the custom_token from the idToken
        and then authenticate the firebase with the custom_token. In return of that what idToken the
        firebase returns is the accessToken which we have to use to authorization
       */
      if (idToken) {
        const parsedToken = (yield call(parseToken, idToken)) as ParsedToken;
        const session = {
          accessToken: idToken,
          customToken,
          hash_token,
        };
        yield put(authenticate({ ...session, parsedToken }));
      } else {
        throw Error('Failed to generate idToken from firebase');
      }
    } else {
      throw Error('Not able to generate the custom token.');
    }
  } catch (error) {
    yield put(authenticateFailed());
    yield call(catchError, {
      title: 'Authenticate Worker',
      error: error as Error,
    });
  }
}

export function* socialLoginWorker({ payload }: PayloadAction<InitializeSocialLoginPayload>): Generator {
  try {
    const response = (yield call(initSocialLogin, payload.socialLoginProviderName)) as SocialLoginSuccessPayload;
    if (response && response?.idToken) {
      yield put(storeIdToken(response.idToken));
      if (payload?.callback?.onSuccess) {
        yield call(payload.callback.onSuccess);
      }
    } else {
      throw Error('Id token not found');
    }
  } catch (error) {
    if (payload?.callback?.onError) {
      yield call(payload?.callback?.onError);
    }
    yield call(catchError, {
      title: 'firebase social login worker',
      error: error as Error,
    });
  }
}

export function* storeQueryParamsWorker(): Generator {
  try {
    const params = yield select(selectQueryParams);
    yield call(localStorageSetItem, QUERY_PARAMS_STORAGE_KEY, JSON.stringify(params));
  } catch (error) {
    yield call(catchError, {
      title: 'storeQueryParamsWorker',
      error: error as Error,
    });
  }
}

export function* unAuthenticateWorker(): Generator {
  try {
    yield call(firebaseLogout);
    yield call(client.resetStore);
  } catch (error) {
    yield call(catchError, {
      title: 'UnAuthenticate Worker',
      error: error as Error,
    });
  }
}

export function* fetchCurrentCandidateUser({ payload }: PayloadAction<{ callback?: SagaCallback }>): Generator {
  try {
    const queryResponse = (yield call(fetchData, {
      queryString: GET_CURRENT_CANDIDATE_USER,
      queryVariables: {},
      forceRefresh: true,
    })) as CurrentCandidateUser;

    const countryCode = (yield call(fetchCountryCodeApi)) as string;
    const canCandidate = first(queryResponse?.can_candidate) || {};
    const authUser = queryResponse?.auth_user_me;
    if (canCandidate && authUser) {
      const data = {
        ...canCandidate,
        user: authUser,
        countryCode,
      } as Candidate;
      yield put(storeCandidateUser(data));
    }
  } catch (error) {
    yield call(catchError, {
      title: 'Fetch Current Candidate User Worker',
      error: error as Error,
      skipToast: false,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* initializeWatcher() {
  yield debounce(1000, initialize.type, initializeWorker);
}

export function* getEmailOtpWatcher() {
  yield takeLatest(getEmailOtp?.type, getEmailOtpWorker);
}

export function* getPhoneFirebaseOtpWatcher() {
  yield takeLatest(getPhoneFirebaseOtp?.type, getPhoneFireBaseOtpWorker);
}

export function* verifyEmailOtpWatcher() {
  yield takeLatest(verifyEmailOtp.type, verifyEmailOtpWorker);
}

export function* verifyFirebaseOtpWatcher() {
  yield takeLatest(verifyFirebaseOtp.type, verifyFirebaseOtpWorker);
}

export function* signupWatcher() {
  yield takeLatest([storeIdToken.type], signupWorker);
}

export function* socialLoginWatcher() {
  yield takeLatest(initializeSocialLogin.type, socialLoginWorker);
}

export function* storeQueryParamsWatcher() {
  yield takeLatest(storeQueryParams.type, storeQueryParamsWorker);
}

export function* fetchCurrentCandidateUserWatcher() {
  yield takeLatest([fetchCandidateUser.type, authenticate.type], fetchCurrentCandidateUser);
}

export function* unAuthenticateWatcher() {
  yield takeLatest(unAuthenticate.type, unAuthenticateWorker);
}

export default function* authRootSaga() {
  yield fork(initializeWatcher);
  yield fork(getEmailOtpWatcher);
  yield fork(getPhoneFirebaseOtpWatcher);
  yield fork(verifyEmailOtpWatcher);
  yield fork(verifyFirebaseOtpWatcher);
  yield fork(signupWatcher);
  yield fork(socialLoginWatcher);
  yield fork(storeQueryParamsWatcher);
  yield fork(fetchCurrentCandidateUserWatcher);
  yield fork(unAuthenticateWatcher);
}
