import { initializeApp } from 'firebase/app';
import { Auth, getAuth, signOut } from 'firebase/auth';
import { catchError } from '@utils/sentry';
import { appName } from '@utils/localStorageHelpers';

interface FirebaseInstance {
  firebaseApp: object;
  fireBaseAuth: Auth;
}

export let firebaseInstance: FirebaseInstance;

export const initializeFirebase = (): FirebaseInstance | undefined => {
  try {
    const config = {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_API_DOMAIN,
    };
    const app = initializeApp(config, { name: appName });
    const auth = getAuth(app);
    firebaseInstance = {
      firebaseApp: app,
      fireBaseAuth: auth,
    };
    return firebaseInstance;
  } catch (error) {
    catchError({ title: 'Firebase initialization', error: error as Error });
  }
};

export const getFirebaseIdToken = async (): Promise<string> => {
  const auth = firebaseInstance?.fireBaseAuth;
  await auth.authStateReady();
  const currentUser = auth?.currentUser;
  let idToken = '';
  if (typeof currentUser?.getIdToken === 'function') {
    idToken = await currentUser?.getIdToken();
  }
  return idToken;
};

export const firebaseLogout = async () => {
  const auth = firebaseInstance?.fireBaseAuth;
  await signOut(auth);
};

export default initializeFirebase;
