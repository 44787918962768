import { call, fork, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { PayloadAction } from '@reduxjs/toolkit';
import postData from '@utils/postData';
import { ConfirmInterviewMeet } from '@containers/Scheduling/types';
import { interviewAccept } from './slice';
import { InterviewAcceptPayload } from './type';
import { ACCEPT_INTERVIEW } from './queries';

export function* interviewAcceptWorker({ payload }: PayloadAction<InterviewAcceptPayload>): Generator {
  try {
    const response = (yield call(postData, {
      queryString: ACCEPT_INTERVIEW,
      payload: payload?.data,
    })) as ConfirmInterviewMeet;

    const confirmInterviewResponse = response?.data?.sch_rsvp_action?.success_count;

    if (payload.callback?.onSuccess && confirmInterviewResponse) {
      yield call(payload.callback.onSuccess, response);
    } else {
      throw new Error('An error occurred while confirming the interview');
    }
  } catch (error) {
    yield call(catchError, { title: 'Confirm Interview', error: error as Error, skipToast: true });

    if (payload.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* interviewAcceptWatcher() {
  yield takeLatest(interviewAccept.type, interviewAcceptWorker);
}

export function* interviewAcceptSaga() {
  yield fork(interviewAcceptWatcher);
}
