import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetBookedSlot, GetMeetingSlots, MeetingSlotsState, Slot } from './type';

export const initialState: MeetingSlotsState = {
  slots: [],
  loading: true,
  error: null,
};

export const meetingSlotsSlice = createSlice({
  name: 'meetingSlots',
  initialState,
  reducers: {
    fetchMeetingSlotsRequest(state, _action: PayloadAction<GetMeetingSlots>) {
      state.loading = true;
    },
    fetchMeetingSlotsSuccess(state, action: PayloadAction<Slot[]>) {
      state.loading = false;
      state.slots = action.payload;
    },
    bookSlotRequest(_state, _action: PayloadAction<GetBookedSlot>) {},
  },
});

export const { fetchMeetingSlotsRequest, fetchMeetingSlotsSuccess, bookSlotRequest } = meetingSlotsSlice.actions;

export default meetingSlotsSlice.reducer;
