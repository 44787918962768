import { call, cancel, delay, fork, take } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { DocumentNode } from 'graphql';
import { getCurrentDate } from '@utils/dateHelpers';
import fetchData from '@utils/fetchData';
import { catchError } from '@utils/sentry';
import { SagaCallback } from '@store/types';

/**
 * Timer Manager
 * Handles the logic for tracking elapsed time in seconds.
 */
export const TimerManager = {
  timer: null as NodeJS.Timeout | null,
  secondsElapsed: 0,

  /**
   * Starts the timer, resetting any previous instance.
   */
  start() {
    this.stop(); // Ensure no duplicate timers are running
    this.timer = setInterval(() => {
      this.secondsElapsed++;
    }, 1000); // Increment every second
  },

  /**
   * Stops and resets the timer.
   */
  stop() {
    this.secondsElapsed = 0;
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = null;
  },

  /**
   * Returns the total elapsed time in seconds.
   */
  getElapsed() {
    return this.secondsElapsed;
  },
};

interface HeartbeatOptions {
  payload: Record<string, unknown>;
  queryString: DocumentNode;
  interval: number; // API call interval in milliseconds
  callback?: SagaCallback;
}

/**
 * Makes an API call for the heartbeat process.
 * @param payload - The request payload
 * @param queryString - The GraphQL query or mutation
 * @param callback - Optional success/error callback
 */
export const makeHeartBeatApiCall = async (
  payload: Record<string, unknown>,
  queryString: DocumentNode,
  callback?: SagaCallback,
): Promise<void> => {
  try {
    await fetchData({
      queryString,
      queryVariables: { ...payload, seconds_elapsed: TimerManager.getElapsed() },
      forceRefresh: true,
    });

    TimerManager.start(); // Restart the timer on successful call
    callback?.onSuccess?.();
  } catch (error) {
    // TimerManager.stop();
    catchError({
      title: 'makeHeartBeatApiCall',
      error: error as Error,
      skipToast: true,
      extraScope: { key: 'payload', value: JSON.stringify(payload) },
    });
    callback?.onError?.(error as Error);
  }
  return Promise.resolve();
};

/**
 * Heartbeat worker saga that handles periodic API calls.
 * @param options - The heartbeat options
 */
export function* createHeartbeatWorker({ payload, queryString, interval, callback }: HeartbeatOptions) {
  try {
    while (true) {
      const startTime = getCurrentDate(); // Get the current time before making the API call

      // Perform the API call
      yield call(makeHeartBeatApiCall, payload, queryString, callback);

      // Calculate elapsed time using Luxon
      const elapsedTime = getCurrentDate().diff(startTime, 'milliseconds').milliseconds;

      // Calculate the remaining time to maintain the desired interval
      const remainingTime = Math.max(0, interval - elapsedTime); // Prevent negative delay

      // Delay the remaining time to make the next call exactly after the intended interval
      yield delay(remainingTime);
    }
  } catch (error) {
    yield call(catchError, {
      title: 'createHeartbeatWorker',
      error: error as Error,
      skipToast: true,
      extraScope: { key: 'payload', value: JSON.stringify(payload) },
    });
    yield delay(2000); // Retry after a short delay on failure
  }
}

interface HeartbeatWatcherOptions extends HeartbeatOptions {
  startAction: string; // Action type to start heartbeat
  stopAction: string; // Action type to stop heartbeat
}

/**
 * Heartbeat watcher saga that manages the lifecycle of the worker saga.
 * @param options - The watcher options
 */
export function* createHeartbeatWatcher({
  startAction,
  stopAction,
  ...workerOptions
}: HeartbeatWatcherOptions): SagaIterator {
  while (true) {
    // Wait for the start action to trigger the worker
    const { payload } = yield take(startAction);

    // Start the heartbeat worker
    const task = yield fork(createHeartbeatWorker, { ...workerOptions, payload });

    // Wait for the stop action to terminate the worker
    yield take(stopAction);

    // Stop the worker and reset the timer
    TimerManager.stop();
    yield cancel(task);
  }
}
