import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import postData from '@utils/postData';
import { catchError } from '@utils/sentry';
import { fetchFormDataRequest, fetchFormDataSuccess } from './slice';
import { fetchFormInstance, formInstanceResponseData } from './types';
import { GET_FORM_INSTANCE_DATA } from './queries';

export function* fetchStandaloneFormDataSaga({ payload }: PayloadAction<fetchFormInstance>): Generator {
  try {
    const { id } = payload;
    const queryResponse = (yield call(postData, {
      queryString: GET_FORM_INSTANCE_DATA,
      payload: { id },
      spreadPayload: true,
    })) as formInstanceResponseData;

    const formTestInstanceResponse = queryResponse?.data?.frm_form_instance[0] || {};
    yield put(fetchFormDataSuccess(formTestInstanceResponse));
  } catch (error) {
    yield call(catchError, { title: 'Standalone forms error', error: error as Error });
  }
}

export function* fetchStandaloneFormWatcherSaga() {
  yield takeLatest(fetchFormDataRequest.type, fetchStandaloneFormDataSaga);
}

export function* standaloneFormRootSaga() {
  yield fork(fetchStandaloneFormWatcherSaga);
}
