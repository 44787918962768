import { first, map } from 'lodash';
import { CanxGetDriveOccurrenceGuestResponse, NormalizedDriveOccurrenceGuestResponse } from './types';

export const normalizeDriveOccurrenceGuestResponse = (
  response: CanxGetDriveOccurrenceGuestResponse | null | undefined,
): NormalizedDriveOccurrenceGuestResponse | null => {
  if (!response) {
    return null;
  }

  const firstOrderItem = response.pay_item ? first(response.pay_item.order_items) : null;
  const firstTransaction = response.pay_item ? first(firstOrderItem?.order.transactions) : null;

  const normalizedResponse: NormalizedDriveOccurrenceGuestResponse = {
    id: response.id,
    from: response.from,
    to: response.to,
    status: response.status,
    meeting: response.meeting
      ? {
          id: response.meeting.id,
          name: response.meeting.name,
          from: response.meeting.from,
          to: response.meeting.to,
          meeting_type: response.meeting.meeting_type,
          meeting_status: response.meeting.meeting_status,
        }
      : null,
    pay_item: response.pay_item
      ? {
          id: response.pay_item.id,
          status: response.pay_item.status,
          order_items: firstOrderItem
            ? {
                item_id: firstOrderItem.item_id,
                order_id: firstOrderItem.order_id,
                unit_price: firstOrderItem.unit_price,
                total_price: firstOrderItem.total_price,
                quantity: firstOrderItem.quantity,
                order: {
                  id: firstOrderItem.order.id,
                  status: firstOrderItem.order.status,
                },
              }
            : null,
          transaction: firstTransaction
            ? {
                payment_id: firstTransaction.payment_id,
                external_id: firstTransaction.external_id,
                type: firstTransaction.type,
                status: firstTransaction.status,
                total_amount: firstTransaction.total_amount,
              }
            : null,
          sku: response.pay_item.sku
            ? {
                currency_code: response.pay_item.sku.currency_code,
                plan_type: response.pay_item.sku.plan_type,
                name: response.pay_item.sku.name,
                refund_rules:
                  map(response.pay_item.sku.refund_rules, (refundRule) => ({
                    refund_percent: refundRule.refund_percent,
                    timeframe: refundRule.timeframe,
                  })) || [],
              }
            : null,
          eligible_refund_rule: response.pay_item.eligible_refund_rule
            ? {
                timeframe: response.pay_item.eligible_refund_rule.timeframe,
                refund_percent: response.pay_item.eligible_refund_rule.refund_percent,
              }
            : null,
          drive_occurrence_guest: response.pay_item.drive_occurrence_guest
            ? {
                from: response.pay_item.drive_occurrence_guest.from,
                id: response.pay_item.drive_occurrence_guest.id,
                status: response.pay_item.drive_occurrence_guest.status,
                to: response.pay_item.drive_occurrence_guest.to,
              }
            : null,
        }
      : null,
    drive_occurrence: response.drive_occurrence
      ? {
          start_date: response.drive_occurrence.start_date,
          end_date: response.drive_occurrence.end_date,
          status: response.drive_occurrence.status,
          workflow_step_id: response.drive_occurrence.workflow_step_id,
          drive_schedule_id: response.drive_occurrence.drive_schedule_id,
        }
      : null,
    drive_schedule: response.drive_schedule
      ? {
          id: response.drive_schedule.id,
          booking_start_date: response.drive_schedule.booking_start_date,
          booking_end_date: response.drive_schedule.booking_end_date,
          drive_type: response.drive_schedule.drive_type,
          name: response.drive_schedule.name,
          start_date: response.drive_schedule.start_date,
          end_date: response.drive_schedule.end_date,
          status: response.drive_schedule.status,
          timezone: response.drive_schedule.timezone,
          location: response.drive_schedule.location,
          slot_capacity: response.drive_schedule.slot_capacity,
          slot_duration: response.drive_schedule.slot_duration,
        }
      : null,
  };

  return normalizedResponse;
};
