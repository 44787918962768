import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthProvider } from '@contexts/AuthContext';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthBook, initializeGrowthBook } from '@utils/growthBook';
import { useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GrowthBookError from '@components/Pages/GrowthBookError';

const RootLayout: React.FC = () => {
  const [gbInitializationState, setGbInitializationState] = useState('initializing');
  const [gbError, setGbError] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/login${location.search}`);
    }
  }, [location.pathname, navigate]);

  React.useEffect(() => {
    setGbInitializationState(() => 'initializing');
    initializeGrowthBook()
      .then(() => {
        setGbInitializationState(() => 'initialized');
      })
      .catch((e) => {
        setGbError(e?.message);
        setGbInitializationState(() => 'failed');
      });
    return () => setGbInitializationState(() => 'initializing');
  }, []);

  if (gbInitializationState === 'failed') {
    return <GrowthBookError errorMessage={gbError} />;
  }

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <AuthProvider>
        <div className="h-screen w-screen">
          <Outlet />
        </div>
        <ToastContainer style={{ width: 'auto' }} />
      </AuthProvider>
    </GrowthBookProvider>
  );
};

export default RootLayout;
