import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SagaCallback } from '@store/types';
import { AsyncState, CollectedRecordedChunk, RecordedChunk, SavedChunkRecord } from './types';

export const initialState: AsyncState = {
  questionAttempted: false, // To check if it is the initial load of the question
  isRecording: false,
  sequence: 1, // To check if the recording is currently happening
  isUploading: false, // To check if the file is currently uploading
  uploadingCompleted: false, // To check if the file upload has stopped (manual stop)
  // isSubmitted: false, // To check if the user clicked on the submit button
  uploadingChunks: [], // Currently uploading chunks
  failedToUploadChunks: [], // Failed to upload chunks
  recordedChunks: [], // Yet to upload chunks
  savedChunkRecords: [], // Already uploaded chunks
  firstChunkUploadFailed: false,
  uploadPercentage: 0,
};

export const asyncSlice = createSlice({
  name: 'async',
  initialState,
  reducers: {
    initialize: () => ({
      ...initialState,
    }),
    startRecording: (state) => {
      state.isRecording = true;
      state.questionAttempted = true;
    },
    stopRecording: (state) => {
      state.isRecording = false;
    },
    uploadChunk: (state, action: PayloadAction<{ chunk: CollectedRecordedChunk }>) => {
      state.isUploading = true;
      const chunk: RecordedChunk = { ...action.payload.chunk, sequence: state.sequence };
      state.sequence += 1;
      const index = state.recordedChunks.findIndex((record) => record.sequence === chunk.sequence);
      if (index === -1) {
        state.recordedChunks.push(chunk);
      } else {
        state.recordedChunks[index] = chunk;
      }
    },
    storeSavedChunk: (state, action: PayloadAction<{ chunk: SavedChunkRecord }>) => {
      const { chunk } = action.payload;
      const index = state.savedChunkRecords.findIndex((record) => record.sequence === chunk.sequence);
      if (index === -1) {
        state.savedChunkRecords.push(chunk);
      } else {
        state.savedChunkRecords[index] = chunk;
      }
    },
    storeFailedToSavedChunk: (state, action: PayloadAction<{ chunk: RecordedChunk }>) => {
      const { chunk } = action.payload;
      const index = state.failedToUploadChunks.findIndex((record) => record.sequence === chunk.sequence);
      if (index === -1) {
        state.failedToUploadChunks.push(chunk);
      } else {
        state.failedToUploadChunks[index] = chunk;
      }
    },
    uploadComplete: (state, action: PayloadAction<{ uploadPercentage: number | string }>) => {
      state.isUploading = false;
      state.uploadingCompleted = true;
      state.uploadPercentage = +action?.payload?.uploadPercentage;
    },
    saveAnswer: (_state, _action: PayloadAction<{ callback: SagaCallback }>) => {
      // state.isSubmitted = true;
    },
  },
});

export const {
  initialize,
  uploadChunk,
  startRecording,
  stopRecording,
  storeSavedChunk,
  uploadComplete,
  storeFailedToSavedChunk,
  saveAnswer,
} = asyncSlice.actions;
