import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InterviewAcceptPayload } from './type';

export const initialState = {};
export const InterviewAcceptSlice = createSlice({
  name: 'interview-decline-scheduling',
  initialState,
  reducers: {
    interviewAccept(_state, _action: PayloadAction<InterviewAcceptPayload>) {},
  },
});

export const { interviewAccept } = InterviewAcceptSlice.actions;
export default InterviewAcceptSlice.reducer;
