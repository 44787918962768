import { gql } from '@apollo/client';

export const ACCEPT_INTERVIEW = gql(`
  mutation acceptInterview($payload: [sch_rsvp_action_input!]!) {
    sch_rsvp_action(data: $payload) {
      error_count
      success_count
    }
  }
`);
