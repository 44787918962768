import store from '@store/index';
import { appName } from '@utils/localStorageHelpers';
import Mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from './mixpanelEvent';
import {
  BulkAnswerSubmitEventProps,
  CandidateScorePageLoadProps,
  DefaultOptionsTypes,
  InstructionEventProps,
  LoginActionPayload,
  MixpanelTrackEventProps,
  TaskTakerFeedbackPageProps,
} from './types';

export const trackEvent = ({ event, options, preAuth }: MixpanelTrackEventProps) => {
  const state = store.getState();
  const user = state?.auth?.user;
  if (!user?.tenant_id && !preAuth) return;
  const defaultOptions: DefaultOptionsTypes = {
    Application: appName,
    'Application Version': process.env.version,
    'Application Environment': process.env.APP_ENV,
    'User Id': user?.user_id ? Number(user?.user_id) : undefined,
    'Organization Id': user?.tenant_id ? Number(user?.tenant_id) : undefined,
    ...options,
  };

  Mixpanel.track(event, { ...defaultOptions });
};

export const socialLoginInitiatedEvent = (options: LoginActionPayload) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOGIN_SOCIAL_MEDIA_INITIATED,
    options,
    preAuth: true,
  });
};

export const socialLoginSuccessEvent = (options: LoginActionPayload) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOGIN_SOCIAL_MEDIA_SUCCESS,
    options,
  });
};

export const instructionPageLandedEvent = (options: InstructionEventProps) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_INSTRUCTION_PAGE_LANDED,
    options,
  });
};

export const bulkAnswerSubmitted = (options: BulkAnswerSubmitEventProps) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_ASSESSMENT_SUBMITTED,
    options,
  });
};

export const candidateAnswerSelected = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_ANSWER_SELECTED,
    options,
  });
};
export const candidateNextQuestionClicked = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_NEXT_QUESTION_CLICKED,
    options,
  });
};
export const candidateLoginOtpRequested = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOGIN_OTP_REQUESTED,
    options,
    preAuth: true,
  });
};

export const candidateLoginSocialMediaLoginFailed = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOGIN_SOCIAL_MEDIA_LOGIN_FAILED,
    options,
    preAuth: true,
  });
};

export const candidateLoginOtpFilledLoginSuccess = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOGIN_OTP_FILLED_LOGIN_SUCCESS,
    options,
  });
};

export const candidateScorePageNextClicked = (options: InstructionEventProps) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_SCORE_PAGE_NEXT_CLICKED,
    options,
  });
};

export const taskTakerFeedbackPage = (options: TaskTakerFeedbackPageProps) => {
  trackEvent({
    event: mixpanelEvents.TASK_TAKER_FEEDBACK_PAGE,
    options,
  });
};

export const candidateScorePageLoad = (options: CandidateScorePageLoadProps) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_SCORE_PAGE_LOAD,
    options,
  });
};

export const candidateAuthenticated = () => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_AUTHENTICATED,
    options: {},
  });
};

export const stopRecordingClick = () => {
  trackEvent({
    event: mixpanelEvents.STOP_RECORDING_CLICK,
    options: {},
  });
};

export const nextAsyncQuestionClick = () => {
  trackEvent({
    event: mixpanelEvents.NEXT_ASYNC_QUESTION_CLICK,
    options: {},
  });
};

export const candidateAsyncPermissionAllowed = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_ASYNC_PERMISSION_ALLOWED,
    options,
  });
};

export const candidateAsyncTogglePreviewClick = (hide: boolean) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_ASYNC_TOGGLE_PREVIEW_CLICK,
    options: { 'Preview Status': hide ? 'hidden' : 'visible' },
  });
};

export const candidatePaymentProceed = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_PAYMENT_PROCEED,
    options,
  });
};

export const candidateCouponCodeApply = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_COUPON_CODE_APPLY,
    options,
  });
};

export const candidateScheduleClick = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_SCHEDULE_CLICK,
    options,
  });
};

export const candidateLoginClick = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOGIN_CLICK,
    options,
  });
};

export const candidateSlotBookingConfirm = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_SLOT_BOOKING_CONFIRM,
    options,
  });
};

export const candidateRegistrationContinue = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_REGISTRATION_CONTINUE,
    options,
  });
};

export const candidateClickGetStartedInstructions = (options: object) => {
  trackEvent({
    event: mixpanelEvents.INSTRUCTIONS_CANDIDATE_CLICK_GET_STARTED,
    options,
  });
};

export const candidateClickTermsAndConditionsInstructionsPage = (options: object) => {
  trackEvent({
    event: mixpanelEvents.INSTRUCTIONS_CANDIDATE_CLICK_TERMS_CONDITIONS,
    options,
  });
};

export const candidateClickPrivacyPolicyInstructionsPage = (options: object) => {
  trackEvent({
    event: mixpanelEvents.INSTRUCTIONS_CANDIDATE_CLICK_PRIVACY_POLICY,
    options,
  });
};

export const candidateLandsOnThankYouPage = (options: object) => {
  trackEvent({
    event: mixpanelEvents.FORM_CANDIDATE_LAND_THANK_YOU_PAGE,
    options,
  });
};

export const candidateLandsOnLobbyWaitPage = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOBBY_WAIT_PAGE_LANDED,
    options,
  });
};

export const candidateTransitionsFromLobbyToAlmostThere = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_LOBBY_TO_ALMOST_THERE_TRANSITION,
    options,
  });
};

export const candidateTransitionsFromAlmostThereToReady = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_ALMOST_THERE_TO_READY_TRANSITION,
    options,
  });
};

export const candidateLandsOnMissedSlotPage = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_MISSED_SLOT_PAGE_LANDED,
    options,
  });
};

export const candidateClicksBackToQueue = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_BACK_TO_QUEUE_CLICKED,
    options,
  });
};

export const candidateSlotBookingPageLanded = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_SLOT_BOOKING_PAGE_LANDED,
    options,
  });
};

export const candidateSlotConfirmed = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_SLOT_CONFIRMED,
    options,
  });
};

export const candidateSlotRescheduled = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_SLOT_RESCHEDULED,
    options,
  });
};

export const candidateLandsOnEarlyBirdPage = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_EARLY_BIRD_PAGE_LANDED,
    options,
  });
};

export const candidateAcceptProposedNewTime = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_ACCEPT_PROPOSED_TIME,
    options,
  });
};

export const candidateFeedbackSubmit = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FEEDBACK_SUBMIT,
    options,
  });
};

export const candidateInterviewEarlyBirdPage = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_INTERVIEW_EARLY_BIRD_PAGE_LANDED,
    options,
  });
};

export const candidateDeclineProposedNewTime = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_DECLINE_PROPOSED_TIME,
    options,
  });
};

export const candidateProposeNewTime = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_PROPOSE_ALTERNATIVE_TIME,
    options,
  });
};

export const candidateInterviewJoin = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_INTERVIEW_JOIN,
    options,
  });
};

export const candidateFeedbackSkip = () => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FEEDBACK_SKIP,
    options: {},
  });
};

export const proposeNewTimeAction = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_PROPOSE_TIME_LAND,
    options,
  });
};

export const proposeNewTimeActionConfirm = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_PROPOSE_TIME_CONFIRM,
    options,
  });
};

export const declineInterviewPageLand = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_DECLINE_LAND,
    options,
  });
};

export const proposeNewTimeClickFromDecline = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_DECLINE_FLOW_PROPOSE_TIME_CLICK,
    options,
  });
};

export const declineConfirmClick = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_DECLINE_CONFIRM,
    options,
  });
};

export const candidateProposeTimeFromDeclineConfirm = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_DECLINE_FLOW_PROPOSE_TIME_CONFIRM,
    options,
  });
};

export const candidateProposeTimeLandFromDecline = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_DECLINE_FLOW_PROPOSE_TIME_LAND,
    options,
  });
};
export const candidateRsvpConfirm = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_RSVP_CONFIRM,
    options,
  });
};
export const candidateFaceAuthAllow = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FACEAUTH_ALLOW,
    options,
  });
};
export const candidateFaceAuthBlock = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FACEAUTH_BLOCK,
    options,
  });
};

export const candidateFaceAuthCapture = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FACEAUTH_CAPTURE,
    options,
  });
};

export const candidateFaceAuthRetake = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FACEAUTH_RETAKE,
    options,
  });
};

export const candidateFaceAuthSubmit = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FACEAUTH_SUBMIT,
    options,
  });
};

export const candidateFaceAuthNextStep = (options: object) => {
  trackEvent({
    event: mixpanelEvents.CANDIDATE_FACEAUTH_NEXTSTEP,
    options,
  });
};

export default {
  bulkAnswerSubmitted,
  candidateAnswerSelected,
  candidateAsyncPermissionAllowed,
  candidateAsyncTogglePreviewClick,
  candidateAuthenticated,
  candidateClickGetStartedInstructions,
  candidateClickPrivacyPolicyInstructionsPage,
  candidateClicksBackToQueue,
  candidateClickTermsAndConditionsInstructionsPage,
  candidateCouponCodeApply,
  candidateLandsOnEarlyBirdPage,
  candidateLandsOnLobbyWaitPage,
  candidateLandsOnMissedSlotPage,
  candidateLandsOnThankYouPage,
  candidateLoginClick,
  candidateLoginOtpFilledLoginSuccess,
  candidateLoginOtpRequested,
  candidateLoginSocialMediaLoginFailed,
  candidateNextQuestionClicked,
  candidatePaymentProceed,
  candidateRegistrationContinue,
  candidateScheduleClick,
  candidateScorePageLoad,
  candidateScorePageNextClicked,
  candidateSlotBookingConfirm,
  candidateSlotBookingPageLanded,
  candidateSlotConfirmed,
  candidateSlotRescheduled,
  candidateTransitionsFromAlmostThereToReady,
  candidateTransitionsFromLobbyToAlmostThere,
  instructionPageLandedEvent,
  nextAsyncQuestionClick,
  socialLoginInitiatedEvent,
  socialLoginSuccessEvent,
  stopRecordingClick,
  taskTakerFeedbackPage,
  trackEvent,
  candidateAcceptProposedNewTime,
  candidateDeclineProposedNewTime,
  candidateProposeNewTime,
  candidateInterviewJoin,
  candidateInterviewEarlyBirdPage,
  candidateFeedbackSkip,
  candidateFeedbackSubmit,
  proposeNewTimeActionConfirm,
  proposeNewTimeAction,
  declineInterviewPageLand,
  proposeNewTimeClickFromDecline,
  declineConfirmClick,
  candidateProposeTimeFromDeclineConfirm,
  candidateProposeTimeLandFromDecline,
  candidateRsvpConfirm,
  candidateFaceAuthAllow,
  candidateFaceAuthBlock,
  candidateFaceAuthCapture,
  candidateFaceAuthRetake,
  candidateFaceAuthSubmit,
  candidateFaceAuthNextStep,
};
