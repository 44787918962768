import { call, fork, takeLatest } from 'redux-saga/effects';
import { catchError } from '@utils/sentry';
import { PayloadAction } from '@reduxjs/toolkit';
import postData from '@utils/postData';
import { acceptNewTime } from './slice';
import { AcceptTimePayload } from './types';
import { CHANGE_INTERVIEWER } from './queries';

export function* acceptTimebyInterviewer({ payload }: PayloadAction<AcceptTimePayload>): Generator {
  try {
    const queryVariables = payload?.data;
    yield call(postData, {
      queryString: CHANGE_INTERVIEWER,
      payload: queryVariables,
      spreadPayload: true,
    });

    if (payload.callback?.onSuccess) {
      yield call(payload.callback.onSuccess);
    } else {
      throw new Error('An error occurred while accepting the proposed time');
    }
  } catch (error) {
    yield call(catchError, { title: 'Accept Time', error: error as Error });

    if (payload.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* acceptTimebyInterviewerWatcher() {
  yield takeLatest(acceptNewTime.type, acceptTimebyInterviewer);
}

export function* acceptProposedTimeSaga() {
  yield fork(acceptTimebyInterviewerWatcher);
}
