import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  AnswersOutput,
  AppStateData,
  AssessmentState,
  ProcessedStartAssessmentResponse,
  ProcessedStartSectionResponse,
  ProcessedStopSectionResponse,
  QuestionOutput,
  STEP_DETAILS,
  SaveAnswerPayload,
  SaveAppStatePayload,
  SectionOutput,
  StartAssessmentError,
  StartAssessmentInput,
  TcfMeasurement,
} from '@containers/Assessment/types';
import { SagaCallback } from '@store/types';

export const initialState: AssessmentState = {
  request: {
    tcfLoading: false,
    isLoading: true,
  },
  data: {
    assessment: null,
    assessmentSection: null,
    currentQuestion: null,
    questions: null,
    answer: null,
    appState: null,
    testInstance: null,
    assessmentSections: [],
    startAssessmentResponse: null,
    startSectionResponse: null,
    next_question_id: null,
    questionsIds: [],
    answers: [],
    currentAssessmentSectionId: undefined,
    parsedMaxSectionTime: null,
    assessmentError: null,
    tcfMeasurement: null,
    candidate_id: undefined,
    step: null,
  },
};

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    initialize: () => ({
      ...initialState,
      request: {
        ...initialState.request,
        isLoading: true,
      },
    }),
    startAssessment: (_state, _action: PayloadAction<StartAssessmentInput>) => {},
    startAssessmentError: (state, action: PayloadAction<StartAssessmentError>) => {
      state.data.assessmentError = action.payload.error;
      state.request.isLoading = false;
    },
    storeAssessment: (state, action: PayloadAction<ProcessedStartAssessmentResponse>) => {
      state.data.assessment = action.payload?.assessment;
      state.data.currentAssessmentSectionId = action.payload?.assessmentSection.id;
      state.data.assessmentSections = action.payload?.assessmentSections;
      state.data.startAssessmentResponse = action.payload?.startAssessmentResponse;
      state.request.isLoading = false;
    },
    resetSectionState: (state) => {
      state.request.tcfLoading = false;
      state.data.assessmentSection = null;
      state.data.currentQuestion = null;
      state.data.questions = null;
      state.data.answer = null;
      state.data.appState = null;
      state.data.startSectionResponse = null;
      state.data.next_question_id = null;
      state.data.questionsIds = [];
      state.data.answers = [];
      state.data.currentAssessmentSectionId = undefined;
      state.data.parsedMaxSectionTime = null;
      state.data.tcfMeasurement = null;
    },
    startSection: (_state, _action: PayloadAction<{ callback: SagaCallback }>) => {},
    storeSection: (state, action: PayloadAction<ProcessedStartSectionResponse>) => {
      state.data.assessmentSection = action.payload.assessmentSection;
      state.data.startSectionResponse = action.payload.startSectionResponse;
      state.data.next_question_id = action.payload.next_question_id;
      state.data.questionsIds = action.payload.questionsIds;
      state.data.parsedMaxSectionTime = action.payload.parsedMaxSectionTime;
      state.data.answers = action.payload.answers;
    },
    submitSection: (_state, _action: PayloadAction<{ callback: SagaCallback }>) => {},
    storeStopSection: (state, action: PayloadAction<ProcessedStopSectionResponse>) => {
      state.data.startSectionResponse = action.payload.startSectionResponse;
    },
    fetchNextQuestionById: (state, _action: PayloadAction<{ callback: SagaCallback }>) => {
      state.data.answer = null;
    },
    fetchNextQuestionFromCollection: (state, _action: PayloadAction<{ callback: SagaCallback }>) => {
      state.data.answer = null;
    },
    storeNextQuestion: (state, action: PayloadAction<{ data: QuestionOutput; next_question_id: number | null }>) => {
      state.data.currentQuestion = action.payload.data;
      state.data.next_question_id = action.payload.next_question_id;
    },
    fetchQuestionsList: () => {},
    storeQuestionList: (state, action: PayloadAction<{ data: QuestionOutput[] }>) => {
      state.data.questions = action.payload.data;
    },
    saveAnswer: (_state, _action: PayloadAction<{ data: SaveAnswerPayload; callback: SagaCallback }>) => {},
    storeAnswer: (state, action: PayloadAction<AnswersOutput>) => {
      state.data.answer = action.payload;
      state.data.answers.push(action.payload);
    },
    storeBulkAnswer: (state, action: PayloadAction<AnswersOutput[]>) => {
      state.data.answers = action.payload;
    },
    stopSection: (
      _state,
      _action: PayloadAction<{
        callback: SagaCallback;
        async?: boolean;
      }>,
    ) => {},
    fetchTcfMeasure: (
      state,
      _action: PayloadAction<{
        callback: SagaCallback;
      }>,
    ) => {
      state.request.tcfLoading = true;
    },
    saveAppState: (_state, _action: PayloadAction<{ data: SaveAppStatePayload; callback?: SagaCallback }>) => {},
    storeAppState: (state, action: PayloadAction<AppStateData>) => {
      state.data.appState = action.payload;
    },
    getAppState: (_state, _action: PayloadAction<{ section_id: SectionOutput['id']; callback?: SagaCallback }>) => {},
    storeTcfMeasurement: (state, action: PayloadAction<TcfMeasurement[]>) => {
      state.request.tcfLoading = false;
      state.data.tcfMeasurement = action.payload;
    },
    fetchTestInstance: (_state, _action: PayloadAction<{ callback: SagaCallback }>) => {},
    saveBulkAnswer: (_state, _action: PayloadAction<{ callback: SagaCallback }>) => {},
    storeStepDetails: (state, action: PayloadAction<STEP_DETAILS>) => {
      state.data.step = action.payload;
    },
  },
});

export const {
  initialize,
  startAssessment,
  startSection,
  resetSectionState,
  submitSection,
  fetchNextQuestionById,
  fetchQuestionsList,
  saveAnswer,
  stopSection,
  fetchTcfMeasure,
  saveAppState,
  storeAssessment,
  storeSection,
  storeNextQuestion,
  storeQuestionList,
  storeAnswer,
  storeAppState,
  getAppState,
  storeStopSection,
  fetchTestInstance,
  startAssessmentError,
  storeTcfMeasurement,
  fetchNextQuestionFromCollection,
  saveBulkAnswer,
  storeBulkAnswer,
  storeStepDetails,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
