import { all, call, fork, put, retry, select, takeEvery } from '@redux-saga/core/effects';
import {
  saveAnswer,
  storeFailedToSavedChunk,
  storeSavedChunk,
  uploadChunk,
  uploadComplete,
} from '@containers/Assessment/AsyncPlayGround/slice';
import { RecordedChunk, SavedChunkRecord } from '@containers/Assessment/AsyncPlayGround/types';
import { selectUser } from '@containers/Auth/selectors';
import postData from '@utils/postData';
import { UPLOAD_FILE } from '@containers/App/queries';
import { FileUpload, FileUploadApiResponse } from '@containers/App/types';
import { catchError } from '@utils/sentry';
import type { User } from '@containers/Auth/types';
import {
  selectFailedToSaveChunks,
  selectIsRecording,
  // selectIsSubmitted,
  // selectIsUploadingCompleted,
  selectRecordedChunks,
  selectSavedChunks,
} from '@containers/Assessment/AsyncPlayGround/selectors';
import { axiosPostData } from '@utils/axiosApi';
import { difference, last, sortBy } from 'lodash';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  AnswersOutput,
  QuestionOutput,
  SaveAnswerPayload,
  SaveAnswersOutput,
  SectionOutput,
} from '@containers/Assessment/types';
import { SagaCallback } from '@store/types';
import { selectCurrentQuestion, selectStartSectionResponse } from '@containers/Assessment/selectors';
import { SAVE_ANSWERS } from '@containers/Assessment/queries';
import { getCurrentDate } from '@utils/dateHelpers';
import { fetchNextQuestionById, saveAppState, storeAnswer } from '@containers/Assessment/slice';
import { nextAsyncQuestionClick } from '@utils/mixpanel/mixpanelActions';

export function* uploadChunkWorker(): Generator {
  const chunks = (yield select(selectRecordedChunks)) as RecordedChunk[];
  const chunk = (yield call(last, chunks)) as RecordedChunk;
  const user = (yield select(selectUser)) as User;
  const extension = chunk.mimeType && chunk.mimeType.split(';')[0].split('/')[1];
  const formattedChunkPayload = {
    file_type_id: 9,
    file: chunk.data,
    timestamp: chunk.timestamp,
    fileSize: chunk.data.size,
    extension,
    original_name: `video_${chunk.sequence}.${extension}`,
    owner_id: user?.user_id,
  };
  try {
    const response = (yield retry(3, 20, postData, {
      queryString: UPLOAD_FILE,
      payload: formattedChunkPayload,
      spreadPayload: true,
    })) as FileUploadApiResponse;
    const { data: _file, ...rest } = chunk;
    if (response && response?.data?.file_upload_file) {
      const fileUploadData = response?.data?.file_upload_file?.data as FileUpload;
      const URL = response?.data?.file_upload_file?.data?.url as string;
      const { url: _url, ...newFileUploadData } = fileUploadData;

      const formData = new FormData();
      Object.keys(newFileUploadData).forEach((key) => {
        formData.append(key, newFileUploadData[key as keyof typeof newFileUploadData]);
      });
      formData.append('file', formattedChunkPayload?.file);
      yield call(axiosPostData, URL, formData);
      yield put(storeSavedChunk({ chunk: { ...rest, file_id: response.data?.file_upload_file?.id as number } }));
    } else {
      throw Error('No Response from file upload api');
    }
  } catch (error) {
    const { file: _file, ...rest } = formattedChunkPayload;
    yield put(storeFailedToSavedChunk({ chunk }));
    yield call(catchError, {
      title: 'Async file update failed',
      extraScope: { key: 'chunk', value: JSON.stringify(rest) },
      error: error as Error,
      skipToast: true,
    });
  }
}

export function* uploadChunkObserverWorker(): Generator {
  try {
    const isRecording = (yield select(selectIsRecording)) as boolean;
    if (!isRecording) {
      const savedChunkRecords = (yield select(selectSavedChunks)) as SavedChunkRecord[];
      const recordedChucks = (yield select(selectRecordedChunks)) as RecordedChunk[];
      const failedToSaveChunks = (yield select(selectFailedToSaveChunks)) as RecordedChunk[];
      const allRecordedSequence = sortBy(recordedChucks, 'sequence').map((record) => record.sequence);
      const allSavedSequence = sortBy(savedChunkRecords, 'sequence').map((record) => record.sequence);
      const diff = difference(allRecordedSequence, allSavedSequence);
      if (diff.length === 0) {
        yield put(uploadComplete({ uploadPercentage: 100 }));
      } else if (diff.length && savedChunkRecords.length + failedToSaveChunks.length === recordedChucks.length) {
        yield put(
          uploadComplete({ uploadPercentage: Math.ceil((savedChunkRecords.length / recordedChucks.length) * 100) }),
        );
      }
    }
  } catch (error) {
    yield call(catchError, {
      title: 'Async post chunk upload failed',
      error: error as Error,
      skipToast: true,
    });
  }
}

export function* saveAnswerWorker({
  payload,
}: PayloadAction<{
  data: SaveAnswerPayload;
  callback: SagaCallback;
}>): Generator {
  try {
    // const isUploadingCompleted = (yield select(selectIsUploadingCompleted)) as boolean;
    // const isSubmitted = (yield select(selectIsSubmitted)) as boolean;
    // if (isUploadingCompleted && isSubmitted) {
    const currentQuestion = (yield select(selectCurrentQuestion)) as QuestionOutput;
    const startSectionResponse = (yield select(selectStartSectionResponse)) as SectionOutput;
    const savedChunks = (yield select(selectSavedChunks)) as SavedChunkRecord[];
    const formattedChunks = savedChunks.map((chunk) => ({
      question_id: currentQuestion.id,
      section_id: startSectionResponse.id,
      answered_at: getCurrentDate()?.toUTC()?.toISO(),
      file_id: chunk.file_id,
      sequence: chunk.sequence,
    }));
    const response = (yield retry(3, 20, postData, {
      queryString: SAVE_ANSWERS,
      payload: formattedChunks,
    })) as SaveAnswersOutput;
    // const response = (yield call(postData, {
    //   queryString: SAVE_ANSWERS,
    //   payload: formattedChunks,
    // })) as SaveAnswersOutput;
    const result = response?.data?.ae_save_answer[0];
    if (result?.success) {
      nextAsyncQuestionClick();
      yield put(storeAnswer(result.data as AnswersOutput));
      yield put(saveAppState({ data: { remaining_time: 0 } }));
      yield put(fetchNextQuestionById({ callback: payload.callback }));
    } else {
      const err = Array.isArray(result?.error_message) ? result?.error_message?.[0] : (result?.error_message as string);
      throw Error(`Failed to save answer:  ${err}`);
    }
    // }
  } catch (error) {
    yield call(catchError, {
      title: 'saveAnswerWorker',
      error: error as Error,
    });
    if (payload?.callback?.onError) {
      yield call(payload.callback.onError, error as Error);
    }
  }
}

export function* saveFileRecordWatcher() {
  yield takeEvery([storeSavedChunk.type, storeFailedToSavedChunk.type], uploadChunkObserverWorker);
}

export function* uploadChunkWatcher() {
  yield takeEvery(uploadChunk.type, uploadChunkWorker);
}

export function* saveAnswerWatcher() {
  yield takeEvery(saveAnswer.type, saveAnswerWorker);
}

export function* asyncAnswerRootSaga() {
  yield all([fork(uploadChunkWatcher), fork(saveFileRecordWatcher), fork(saveAnswerWatcher)]);
}
