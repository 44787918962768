import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DeclineProposedTimePayload } from './types';

export const initialState = {};
export const DeclineProposedTimeSlice = createSlice({
  name: 'declineProposedTime',
  initialState,
  reducers: {
    declineNewTime(_state, _action: PayloadAction<DeclineProposedTimePayload>) {},
  },
});

export const { declineNewTime } = DeclineProposedTimeSlice.actions;
export default DeclineProposedTimeSlice.reducer;
