import { authSlice } from '@containers/Auth/slice';
import { combineSlices } from '@reduxjs/toolkit';
import { assessmentSlice } from '@containers/Assessment/slice';
import { meetingSlotsSlice } from '@containers/Scheduling/SlotBooking/slice';
import { candidateSlice } from '@containers/CandidateRegistration/slice';
import { asyncSlice } from '@containers/Assessment/AsyncPlayGround/slice';
import { paymentSlice } from '@containers/Payment/slice';
import { schedulingSlice } from '@containers/Scheduling/slice';
import { driveSlice } from '@containers/Drive/slice';
import { candidateProfileSlice } from '@containers/BiometricAuthentication/FaceAuth/slice';
import { standaloneFormSlice } from '../containers/Standalone/slice';

export default combineSlices({
  [authSlice.name]: authSlice.reducer,
  [assessmentSlice.name]: assessmentSlice.reducer,
  [meetingSlotsSlice.name]: meetingSlotsSlice.reducer,
  [candidateSlice.name]: candidateSlice.reducer,
  [asyncSlice.name]: asyncSlice.reducer,
  [paymentSlice.name]: paymentSlice.reducer,
  [schedulingSlice.name]: schedulingSlice.reducer,
  [driveSlice.name]: driveSlice.reducer,
  [standaloneFormSlice.name]: standaloneFormSlice.reducer,
  [candidateProfileSlice.name]: candidateProfileSlice.reducer,
});
