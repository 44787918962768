import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { paymentSlice } from '@containers/Payment/slice';

export const selectPayment = createSelector(
  (state: RootState) => state,
  (state) => state[paymentSlice.name],
);

export const selectCouponDetails = createSelector(selectPayment, (state) => state.couponDetails);

export const selectPaymentDetails = createSelector(selectPayment, (state) => state.data);
